import { classes } from '$stores/classes'
import { type NodeData, type Text, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

type DefaultRenderersParams = {
	rendererMapKey: string
	rendererType: string
	nodeType: string
	tag: string
}
type Renderers = {
	renderNode?: {
		BLOCKS?: {
			default?(node: NodeData, next: (content: NodeData) => string): string
			(node: NodeData, next?: (content: NodeData) => string): string
		}
		INLINES?: {
			default?(node: NodeData, next: (content: NodeData) => string): string
			(node: NodeData, next?: (content: NodeData) => string): string
		}
	}
	renderMark?: {
		MARKS?: {
			default?(text: Text): string
			(text: Text): string
		}
	}
}
type BuildRenderOptionsParams = {
	links?: object
	renderers?: Renderers
}

const rendererMaps = {
	renderNode: {
		BLOCKS: {
			[BLOCKS.DOCUMENT]: 'doc',
			[BLOCKS.PARAGRAPH]: 'p',
			[BLOCKS.HEADING_1]: 'h1',
			[BLOCKS.HEADING_2]: 'h2',
			[BLOCKS.HEADING_3]: 'h3',
			[BLOCKS.HEADING_4]: 'h4',
			[BLOCKS.HEADING_5]: 'h5',
			[BLOCKS.HEADING_6]: 'h6',
			[BLOCKS.UL_LIST]: 'ul',
			[BLOCKS.OL_LIST]: 'ol',
			[BLOCKS.LIST_ITEM]: 'li',
			[BLOCKS.QUOTE]: 'blockquote',
			[BLOCKS.HR]: 'hr'
		},
		INLINES: {
			[INLINES.HYPERLINK]: 'a',
			[INLINES.ENTRY_HYPERLINK]: 'a',
			[INLINES.ASSET_HYPERLINK]: 'a',
			[INLINES.RESOURCE_HYPERLINK]: 'a'
		}
	},
	renderMark: {
		MARKS: {
			[MARKS.BOLD]: 'b',
			[MARKS.ITALIC]: 'i',
			[MARKS.UNDERLINE]: 'u',
			[MARKS.CODE]: 'code',
			[MARKS.SUPERSCRIPT]: 'sup',
			[MARKS.SUBSCRIPT]: 'sub',
			[MARKS.STRIKETHROUGH]: 's'
		}
	}
}

const defaultRenderers = function ({ rendererMapKey, rendererType, nodeType, tag }: DefaultRenderersParams) {
	const missingRequiredParams: string[] = []
	if (missingRequiredParams.length === 0) {
		const renderers: Renderers = {
			renderNode: {
				BLOCKS: {
					default: function (node: NodeData, next) {
						return `<${tag} class="${classes.blocks[tag]}">${next(node.content)}</${tag}>`
					},
					[BLOCKS.EMBEDDED_ASSET]: function (node: NodeData) {
						const target = node.data.target
						if (target.fields) return `<img loading="lazy" src="${target.fields.file.url}" alt="${target.fields.title}" />`
					},
					[BLOCKS.EMBEDDED_ENTRY]: function (node: NodeData) {
						// console.log(`${[BLOCKS.EMBEDDED_ENTRY]}:`, node)
						if (node.data.target.sys.contentType.sys.id === 'siteMap' && node.data.target.fields?.path) {
							return `<a href={${node.data.target.fields.path}} class="${classes.inlines.a}">stuff</a>`
						}
					}
				},
				INLINES: {
					default: function (node: NodeData, next) {
						return `<${tag} href="${node.data.uri}" class="${classes.inlines[tag]}">${next(node.content)}</${tag}>`
					}
				}
			},
			renderMark: {
				MARKS: {
					default: function (text) {
						return `<${tag} class="${classes.marks[tag]}">${text}</${tag}>`
					}
				}
			}
		}
		const renderer = renderers[rendererMapKey][rendererType][nodeType] || renderers[rendererMapKey][rendererType].default
		return renderer
	} else throw Error(`Required parameters missing from function "buildRendererOptions": [${missingRequiredParams.join()}]`)
}

function buildRenderOptions({ renderers }: BuildRenderOptionsParams) {
	let renderOptions = renderers
	if (!renderOptions) {
		renderOptions = {}
		for (const rendererMapKey in rendererMaps) {
			const rendererMap = rendererMaps[rendererMapKey]

			renderOptions[rendererMapKey] = {}

			for (const rendererType in rendererMap) {
				const nodeTypeMaps = rendererMap[rendererType]

				for (const nodeType in nodeTypeMaps) {
					const tag = nodeTypeMaps[nodeType]

					renderOptions[rendererMapKey][nodeType] = defaultRenderers({ rendererMapKey, rendererType, nodeType, tag })
				}
			}
		}
	}

	return renderOptions
}

const renderOptions = buildRenderOptions({})

export { defaultRenderers, buildRenderOptions, renderOptions }
