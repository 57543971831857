import { twMerge } from 'tailwind-merge'

function classParser({ classVars = {}, classOptions = {} }) {
	const classArray = []

	for (const classVar in classVars) {
		if (Object.hasOwnProperty.call(classVars, classVar)) {
			const userInput = classVars[classVar]
			let classValue
			if (Object.hasOwnProperty.call(classOptions, classVar)) {
				const classOption = classOptions[classVar]
				classValue = Object.hasOwnProperty.call(classOption, userInput) ? classOption[userInput] : userInput
			} else if (userInput) classValue = userInput

			if (classValue) classArray.push(classValue)
		}
	}
	return classArray.join(' ')
}

const blockClasses = {
	hero: ' font-headline font-bold     text-4xl                 md:text-5xl             xl:text-6xl leading-tight                        pb-4 break-words md:break-normal hyphens-none antialiased md:subpixel-antialiased',
	h1: '   font-headline font-bold     text-3xl                 md:text-5xl             xl:text-6xl leading-tight                        pb-8 break-words md:break-normal hyphens-none antialiased md:subpixel-antialiased',
	sh1: '                font-normal   text-lg                  md:text-xl  lg:text-2xl xl:text-3xl leading-tight',
	intro: 'font-headline font-bold     text-3xl   sm:text-4xl               lg:text-5xl                             text-center          pb-4 break-words md:break-normal hyphens-none antialiased md:subpixel-antialiased',
	h2: '   font-headline font-semibold text-2xl   sm:text-3xl   md:text-3xl lg:text-4xl                                            pt-12 pb-4 break-words md:break-normal hyphens-none antialiased md:subpixel-antialiased',
	h3: '   font-headline font-semibold text-lg                  md:text-xl                                                         pt-4  pb-2 break-words md:break-normal hyphens-none antialiased md:subpixel-antialiased',
	sh3: '                font-normal   text-sm                                                      leading-tight   text-gray-500 -pt-4  pb-4',
	h4: '   font-headline font-semibold text-base                md:text-lg                                                         pt-4  pb-2',
	h5: '   font-headline font-normal   text-base                md:text-lg                                                         pt-4  pb-2',
	h6: '   font-headline font-normal   text-base                md:text-lg                                                         pt-4  pb-2',
	p: '                  font-normal   text-base                                                                          pb-4                                hyphens-none antialiased md:subpixel-antialiased',

	ul: 'font-normal list-disc pb-4 pl-4',
	ol: 'font-normal list-decimal pb-4 pl-4',
	li: '[&>*]:inline'
}
const markClasses = {
	bold: 'font-bold',
	italic: 'italic',
	underline: 'underline',
	code: 'font-mono',
	superscript: '',
	subscript: '',
	strikethrough: ''
}
const inlineClasses = {
	a: 'text-tee-blue-default decoration-gray-200 decoration-2 hover:underline hover:decoration-tee-blue-default'
}
const componentClasses = {
	hero: {
		simple: {
			height: 'md:min-h-[269px] lg:min-h-[469px]',
			heading: twMerge(blockClasses.h1, '[text-shadow:_0px_1px_3px_black]'),
			text: twMerge(blockClasses.sh1, '[text-shadow:_0px_1px_3px_black]'),
			image: 'w-full object-cover min-h-[510px] md:h-[550px] xl:h-[650px]'
		},
		old: {
			height: 'md:min-h-[508px] lg:min-h-[708px]',
			heading: twMerge(blockClasses.h1, '[text-shadow:_0px_1px_3px_black]'),
			text: twMerge(blockClasses.sh1, '[text-shadow:_0px_1px_3px_black]'),
			image: 'w-full object-cover min-h-[510px] md:h-[550px] xl:h-[650px]'
		},
		default: {
			height: twMerge('min-h-[630px]', 'md:min-h-[700px]'),
			heading: twMerge(blockClasses.hero),
			text: twMerge('text-xl', 'hyphens-none', 'antialiased', 'md:subpixel-antialiased'),
			image: twMerge('h-full', 'object-contain')
		}
	}
}

for (const key in blockClasses) {
	if (Object.hasOwnProperty.call(blockClasses, key)) {
		const block:string = blockClasses[key]
		blockClasses[key] = block.replace(/\s+/g, ' ')
	}
}
const classes = {blocks: blockClasses, marks: markClasses, inlines: inlineClasses, components: componentClasses}

export { classParser, classes }
